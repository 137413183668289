$('.selectpicker').selectpicker();

$('.navbar-odz .nav > li:first-child').addClass('active');


jQuery(document).ready(function ($) {
    $('#news-slider').royalSlider({
        arrowsNav: true,
        fadeinLoadedSlide: true,
        controlNavigationSpacing: 0,
        controlNavigation: 'thumbnails',
        transitionType: 'fade',
        transitionSpeed: 300,

        thumbs: {
            autoCenter: false,
            fitInViewport: true,
            orientation: 'vertical',
            spacing: 0,
            paddingBottom: 0
        },
        keyboardNavEnabled: true,
        imageScaleMode: 'fill',
        imageAlignCenter: true,
        slidesSpacing: 0,
        loop: false,
        loopRewind: true,
        numImagesToPreload: 3,
        // video: {
        //     autoHideArrows:true,
        //     autoHideControlNav:false,
        //     autoHideBlocks: true
        // },
        autoScaleSlider: true,
        globalCaption: true
        // autoScaleSliderWidth: 960,
        // autoScaleSliderHeight: 367,

        /* size of all images http://help.dimsemenov.com/kb/royalslider-jquery-plugin-faq/adding-width-and-height-properties-to-images */
        // imgWidth: 640,
        // imgHeight: 360

    });
});
$('.footer-match-height').matchHeight();
$('.page-match-height').matchHeight();
$('.institucija-singl').matchHeight();

function odzColNum() {
    var odzColNum = 0;
    var odzPageWidth = $('.odz-page-footer').width();
    console.log(odzPageWidth);
    if (odzPageWidth < 600) {
        odzColNum = 2;
    } else {
        odzColNum = 3;
    }
    return odzColNum;
}
function doOnResize() {
    // remove all parent make by your jquery columnizer
    $('.column > *').unwrap();
    $('.footer-menu-list').columnize({
        columns: odzColNum(),
        buildOnce: true
    });

    footerBg();
}
function footerBg() {
    var fcHeight = $('.footer-contact').outerHeight();
    console.log(fcHeight);
    $('.footer-contact-bg').height(fcHeight).addClass('visible');

}

var resizeTimer;
$(window).resize(function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(doOnResize, 300);
});

$(document).ready(function () {
    doOnResize();
    // fonrOdzResizer();

});
$(window).load(function () {
    footerBg();
});

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
});


// ********************************************************************************************************************
// Font size change with Cookie
// ********************************************************************************************************************
$(document).ready(function () {

    var txt_size = 1;
    var curSizeSmall = 0;
    var curSizeNormal = 0;
    var curSizeDefault = 0;

    var curSizeTitle_1 = 0;
    var curSizeTitle_2 = 0;
    var curSizeTitle_3 = 0;

    curSizeSmall = 12;
    curSizeNormal = 14;
    curSizeDefault = 16;

    curSizeTitle_1 = 32;
    curSizeTitle_2 = 24;
    curSizeTitle_3 = 18;

    function incenseFont() {
        curSizeSmall += 3;
        curSizeNormal += 2;
        curSizeDefault += 1;

        curSizeTitle_1 += 1;
        curSizeTitle_2 += 1;
        curSizeTitle_3 += 1;
    }

    function decenseFont() {
        curSizeSmall -= 3;
        curSizeNormal -= 2;
        curSizeDefault -= 1;

        curSizeTitle_1 -= 1;
        curSizeTitle_2 -= 1;
        curSizeTitle_3 -= 1;
    }


    for (i = 1; i < getCookieTxtSize(); i++) {
        increaseChar();
        // console.log("uvecaj za jedan increaseChar() \n");
    }


    // console.log("getCookieTxtSize = ", getCookieTxtSize());


    function increaseChar() {

        if (txt_size < 8) {
            txt_size++;
            incenseFont();
        }
        if (curSizeSmall <= 18)
            $('.small-txt').css('font-size', curSizeSmall);
        if (curSizeNormal <= 24)
            $('.normal-txt').css('font-size', curSizeNormal);
        if (curSizeDefault <= 24)
            $('.default-txt').css('font-size', curSizeDefault);
        if (curSizeTitle_1 <= 36)
            $('.title-txt-1').css('font-size', curSizeTitle_1);
        if (curSizeTitle_2 <= 32)
            $('.title-txt-2').css('font-size', curSizeTitle_2);
        if (curSizeTitle_3 <= 28)
            $('.title-txt-3').css('font-size', curSizeTitle_3);

    }

    function decreasesChar() {
        if (txt_size > 1) {
            txt_size--;
            decenseFont();
        }

        if (curSizeSmall >= 12)
            $('.small-txt').css('font-size', curSizeSmall);

        if (curSizeNormal >= 14)
            $('.normal-txt').css('font-size', curSizeNormal);

        if (curSizeDefault >= 16)
            $('.default-txt').css('font-size', curSizeDefault);

        if (curSizeTitle_1 >= 32)
            $('.title-txt-1').css('font-size', curSizeTitle_1);

        if (curSizeTitle_2 >= 24)
            $('.title-txt-2').css('font-size', curSizeTitle_2);

        if (curSizeTitle_3 >= 18)
            $('.title-txt-3').css('font-size', curSizeTitle_3);


    }

    function resetChar() {
        curSizeSmall = 12;
        curSizeNormal = 14;
        curSizeDefault = 16;

        curSizeTitle_1 = 32;
        curSizeTitle_2 = 24;
        curSizeTitle_3 = 18;
        txt_size = 1;

        // small-txt
        $('.small-txt').css('font-size', curSizeSmall);
        // normal-txt
        $('.normal-txt').css('font-size', curSizeNormal);
        // default-txt
        $('.default-txt').css('font-size', curSizeDefault);
        // title-txt-1
        $('.title-txt-1').css('font-size', curSizeTitle_1);
        // title-txt-2
        $('.title-txt-2').css('font-size', curSizeTitle_2);
        // title-txt-3
        $('.title-txt-3').css('font-size', curSizeTitle_3);


    }

    $('#increase-char').click(function () {
        increaseChar();
        matchHeightAll();
        document.cookie = "txt_size" + "=" + txt_size;
        console.log("txt_size = ", txt_size);
        console.log("curSizeSmall = ", curSizeSmall);
    });

    $('#reset-char').click(function () {
        resetChar();
        matchHeightAll();
        document.cookie = "txt_size" + "=" + txt_size;
        console.log("txt_size", txt_size);
    });
    $('#decreases-char').click(function () {
        decreasesChar();
        matchHeightAll();
        document.cookie = "txt_size" + "=" + txt_size;
        console.log("txt_size", txt_size);
    });


    function matchHeightAll() {
        $('.home-info .header').matchHeight();
        $('.home-info .body').matchHeight();
        $('.page-match-height').matchHeight();
        $('.institucija-singl').matchHeight();
    }


    $('#decfont').click(function () {
        curSize = parseInt($('#content').css('font-size')) - 2;
        if (curSize >= 12)
            $('#content').css('font-size', curSize);
    });

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function getCookieTxtSize() {
        var txt_size_cookie = getCookie("txt_size");
        if (txt_size_cookie != "") {
            txt_size_cookie = txt_size_cookie;
        } else {
            txt_size_cookie = 1;
        }
        return txt_size_cookie
    }


});

$('.static-page .odz-page-body a > img').parent().addClass('popup-img').wrap('<div class="popup-img-wrap"></div>');
// $('.odz-page-body a > img').parent();
$('.static-page .odz-page-body a > img').parent().append('<span class="img-icon icon-search"></span>');


$(document).ready(function () {
    $('.odz-page-body').magnificPopup({
        delegate: '.popup-img',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function (item) {
                return item.el.attr('title') + '<small>odzaci.rs</small>';
            }
        }
    });
});


if ($('#back-to-top').length) {
    var scrollTrigger = 100,
        backToTop = function () {
            var scrollTop = $(window).scrollTop();
            if (scrollTop > scrollTrigger) {
                $('#back-to-top').addClass('show');
            } else {
                $('#back-to-top').removeClass('show');
            }
        };
    backToTop();
    $(window).on('scroll', function () {
        backToTop();
    });
    $('#back-to-top').on('click', function (e) {
        e.preventDefault();
        $('html,body').animate({
            scrollTop: 0
        }, 700);

    });
}


$(function() {
    $('a.scrole-link').click(function() {

        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 700);
                return false;
            }
        }
    });
});






// bootstrap-datepicker.sr-latin.min.js
!function(a){a.fn.datepicker.dates["sr-latin"]={days:["Nedelja","Ponedeljak","Utorak","Sreda","Četvrtak","Petak","Subota"],daysShort:["Ned","Pon","Uto","Sre","Čet","Pet","Sub"],daysMin:["N","Po","U","Sr","Č","Pe","Su"],months:["Januar","Februar","Mart","April","Maj","Jun","Jul","Avgust","Septembar","Oktobar","Novembar","Decembar"],monthsShort:["Jan","Feb","Mar","Apr","Maj","Jun","Jul","Avg","Sep","Okt","Nov","Dec"],today:"Danas",weekStart:1,format:"dd.mm.yyyy"}}(jQuery);


// bootstrap-datepicker.rs.min.js
!function(a){a.fn.datepicker.dates.rs={days:["Недеља","Понедељак","Уторак","Среда","Четвртак","Петак","Субота"],daysShort:["Нед","Пон","Уто","Сре","Чет","Пет","Суб"],daysMin:["Н","По","У","Ср","Ч","Пе","Су"],months:["Јануар","Фебруар","Март","Април","Мај","Јун","Јул","Август","Септембар","Октобар","Новембар","Децембар"],monthsShort:["Јан","Феб","Мар","Апр","Мај","Јун","Јул","Авг","Сеп","Окт","Нов","Дец"],today:"Данас",weekStart:1,format:"dd.mm.yyyy"}}(jQuery);


$('.archive-search .input-daterange').datepicker({
    language:"rs"
});








